import React from 'react'
import { Link, graphql } from 'gatsby'

import { BsFillPlayFill } from 'react-icons/bs'
import { FiArrowLeft, FiGlobe } from 'react-icons/fi'
import { GrClose } from 'react-icons/gr'
import {
  AiFillFacebook,
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiFillYoutube,
} from 'react-icons/ai'

import Fade from 'react-reveal/Fade'

import Vimeo from '@u-wave/react-vimeo'

import Layout, { PreFooter } from '../components/Layout'
import SEO from '../components/seo'

import { get } from 'lodash-es'

import { format } from 'date-fns'

class BlogPostTemplate extends React.Component {
  state = {
    page: 1,
    modalEpisode: null,
  }

  render() {
    const post = this.props.data.mdx
    const { frontmatter } = post
    const siteTitle = this.props.data.site.siteMetadata.title

    const { pageContext } = this.props
    const { previous } = pageContext

    let preFooterPost = previous
    if (!preFooterPost) {
      preFooterPost = this.props.data.allMdx.edges[0].node
    }

    const shareImage = frontmatter.shareImage || frontmatter.thumbnail

    const numberOfEpisodes = frontmatter.episodes.length

    const EPISODES_PER_PAGE = 8
    const episodesToShow = frontmatter.episodes
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .slice(0, this.state.page * EPISODES_PER_PAGE)

    const isBrowser = typeof window !== `undefined`

    const { modalEpisode } = this.state

    const toggleVideoModal = episode => {
      this.setState({
        ...this.state,
        modalEpisode: episode,
      })
    }

    const socials_twitter = get(frontmatter, 'social_links.twitter')
    const socials_youtube = get(frontmatter, 'social_links.youtube')
    const socials_facebook = get(frontmatter, 'social_links.facebook')
    const socials_instagram = get(frontmatter, 'social_links.instagram')

    return (
      <Layout
        HeaderExtension={
          <div className="header_extension show">
            {/* <Fade duration={1000} delay={125} distance={'25%'}> */}
            <div className="cp-bar">
              <div className="container">
                <Link to="/shows">
                  <FiArrowLeft /> Back to all shows
                </Link>
              </div>
            </div>
            {/* </Fade> */}

            <div className="cp-hero">
              <Fade bottom duration={1000} delay={125} distance={'2.5%'}>
                <div
                  className="cp-hero-bg"
                  style={{ backgroundImage: `url(${frontmatter.thumbnail})` }}
                ></div>
              </Fade>

              <div className="container">
                <div className="cp-hero-meta">
                  <Fade top duration={1000} delay={125} distance={'10%'}>
                    <div className="columns is-vcentered">
                      <div className="column is-3-widescreen is-3-desktop is-5-tablet cp-img">
                        <img src={frontmatter.hero_logo} />
                      </div>

                      <div className="column is-8-widescreen is-8-desktop is-6-tablet cp-text">
                        <h3>{frontmatter.title}</h3>

                        {frontmatter.description && (
                          <p className="cp-desc">{frontmatter.description}</p>
                        )}

                        <div className="cp-links">
                          <a
                            href={frontmatter.link}
                            target="_blank"
                            rel="nofollow"
                            className={`cp-website ${
                              (!!socials_facebook ||
                                !!socials_instagram ||
                                !!socials_twitter ||
                                !!socials_youtube) === true
                                ? 'cp-bordered'
                                : ''
                            }`}
                          >
                            <FiGlobe />
                            Visit website
                          </a>

                          {socials_twitter && (
                            <a
                              href={socials_twitter}
                              target="_blank"
                              rel="nofollow"
                              className="cp-larger"
                            >
                              <AiOutlineTwitter />
                            </a>
                          )}

                          {socials_youtube && (
                            <a
                              href={get(frontmatter, 'social_links.youtube')}
                              target="_blank"
                              rel="nofollow"
                              className="cp-larger"
                            >
                              <AiFillYoutube />
                            </a>
                          )}

                          {socials_facebook && (
                            <a
                              href={get(frontmatter, 'social_links.facebook')}
                              target="_blank"
                              rel="nofollow"
                              className="cp-larger"
                            >
                              <AiFillFacebook />
                            </a>
                          )}

                          {socials_instagram && (
                            <a
                              href={get(frontmatter, 'social_links.instagram')}
                              target="_blank"
                              rel="nofollow"
                              className="cp-larger"
                            >
                              <AiOutlineInstagram />
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
            {/* </Fade> */}
          </div>
        }
        location={this.props.location}
        title={siteTitle}
      >
        <SEO
          title={frontmatter.title}
          image={shareImage ? `https://knocking.com${shareImage}` : null}
          description={frontmatter.description}
          keywords={frontmatter.keywords}
        />

        <div className="show-single">
          <div className="container">
            {episodesToShow.map((episode, i) => (
              <Fade
                bottom
                duration={1000}
                delay={100 * (i / 9.3)}
                distance={'25%'}
              >
                <div
                  className="cp-episode"
                  onClick={e => {
                    e.preventDefault()

                    toggleVideoModal(episode)
                  }}
                >
                  <div className="columns is-vcentered">
                    <div className="column is-3-widescreen is-3-desktop is-4-tablet cp-image">
                      <img src={episode.image} />

                      <div className="cp-play">
                        <BsFillPlayFill />
                      </div>
                    </div>

                    <div className="column is-1 is-wide-only"></div>

                    <div className="column is-8-widescreen is-8-desktop is-7-tablet">
                      <p className="cp-date">
                        {format(new Date(episode.date), 'MMMM dd, yyyy')}
                      </p>

                      <p className="cp-title">{episode.title}</p>
                    </div>
                  </div>
                </div>
              </Fade>
            ))}
          </div>

          {modalEpisode && (
            <div
              className="video-modal"
              onClick={() => toggleVideoModal(null)}
              role="presentation"
            >
              <div className="video-modal-body">
                <div className="video-modal-inner">
                  <a
                    href="/"
                    onClick={e => {
                      e.preventDefault()

                      toggleVideoModal(null)
                    }}
                    className="cp-in"
                  >
                    <GrClose />
                  </a>

                  <a
                    href="/"
                    onClick={e => {
                      e.preventDefault()

                      toggleVideoModal(null)
                    }}
                    className="cp-out"
                  >
                    <GrClose />
                  </a>

                  <Vimeo video={modalEpisode.vimeo_id} autoplay />

                  <div className="cp-text">
                    <p className="cp-date">{modalEpisode.date}</p>

                    <p className="cp-title">{modalEpisode.title}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {EPISODES_PER_PAGE * this.state.page < numberOfEpisodes && (
            <div className="container cp-load-more">
              <a
                href="#"
                className="button primary"
                onClick={e => {
                  e.preventDefault()

                  this.setState({ ...this.state, page: this.state.page + 1 })
                }}
              >
                Load more
              </a>
            </div>
          )}

          <div className="container">
            <div className="post-body content"></div>
          </div>

          {preFooterPost && (
            <PreFooter
              styles={{
                paddingTop: '7em',
              }}
              title="Browse more of our big hits"
              titleClickable={true}
              linkText="View the full roster"
              linkTo="/shows"
            />
          )}
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        keywords
        thumbnail
        episodes {
          date
          title
          vimeo_id
          image
        }
        hero_logo
        link
        social_links {
          facebook
          instagram
          twitter
          youtube
        }
      }
      body
    }
  }
`
